/** 
 * @description 网站的配置
 */

// 背景音乐地址
export const audioUrl = require('../assets/bg.mp3')
// 备案号
// export const remark = '蜀ICP备2021006763号'
export const remark = 'R2fun-20220707'
// 备案号地址
export const remarkUrl = 'https://tieba.baidu.com/f?kw=%E9%9F%B3%E9%80%9F%E4%B9%90%E8%B6%A3&tp=0'
// 视频地址
export const videoSrc = require('../assets/video.mp4')
// 中部浮现图
export const imgList = [{
    img: require('../assets/imgList/imgList01.png'),
    text: '测试文字'
},
{
    img: require('../assets/imgList/imgList02.png'),
    text: '测试文字'
},
{
    img: require('../assets/imgList/imgList03.png'),
    text: '测试文字'
},
{
    img: require('../assets/imgList/imgList04.png'),
    text: '测试文字'
}]
// 底部说明
export const explain = '抵制不良游戏 拒绝盗版游戏 注意自我保护 谨防受骗上当'
export const explains = '适度游戏益脑 沉迷游戏伤身 合理安排时间 享受健康生活'
// -------------------------头部导航------------------------------------

//官网首页
export const homeUrl = '#'
//下载专区
export const downUrl = 'https://qm.qq.com/q/H3r6H1NRw6'
//游戏介绍
export const introductionUrl = 'https://tieba.baidu.com/f?kw=%E9%9F%B3%E9%80%9F%E4%B9%90%E8%B6%A3&tp=0'
//问题反馈
export const feedbackUrl = 'https://tieba.baidu.com/f?kw=%E9%9F%B3%E9%80%9F%E4%B9%90%E8%B6%A3&tp=0'
// ------------------------轮播图---------------------------------------
export const bannerList = [
    {
        url: 'https://tieba.baidu.com/f?kw=%E9%9F%B3%E9%80%9F%E4%B9%90%E8%B6%A3&tp=0',
        img: require('../assets/banner/banner01.png')
    },
    {
        url: 'https://tieba.baidu.com/f?kw=%E9%9F%B3%E9%80%9F%E4%B9%90%E8%B6%A3&tp=0',
        img: require('../assets/banner/banner02.png')
    },
    {
        url: 'https://tieba.baidu.com/f?kw=%E9%9F%B3%E9%80%9F%E4%B9%90%E8%B6%A3&tp=0',
        img: require('../assets/banner/banner03.png')
    },
]
// ------------------------底部轮播图---------------------------------------
export const bannerBottomList = [
    {
        url: 'https://tieba.baidu.com/f?kw=%E9%9F%B3%E9%80%9F%E4%B9%90%E8%B6%A3&tp=0',
        img: require('../assets/banner/banner04.png')
    },
    {
        url: 'https://tieba.baidu.com/f?kw=%E9%9F%B3%E9%80%9F%E4%B9%90%E8%B6%A3&tp=0',
        img: require('../assets/banner/banner05.png')
    },
    {
        url: 'https://tieba.baidu.com/f?kw=%E9%9F%B3%E9%80%9F%E4%B9%90%E8%B6%A3&tp=0',
        img: require('../assets/banner/banner06.png')
    },
]
// -------------------------动感音乐专区------------------------------------
//动感音乐专区
export const popMusic = {
    text: '动感音乐专区',
    url: 'https://tieba.baidu.com/f?kw=%E9%9F%B3%E9%80%9F%E4%B9%90%E8%B6%A3&tp=0',
    img: require('../assets/popMusic.png'),
}
// -------------------------潮流搭配专区------------------------------------

//潮流搭配专区
export const tide = {
    text: '潮流搭配专区',
    url: 'https://tieba.baidu.com/f?kw=%E9%9F%B3%E9%80%9F%E4%B9%90%E8%B6%A3&tp=0',
    img: require('../assets/tideImg.png'),
}

// -------------------------下载中心------------------------------------
// 下载地址
export const downCenterUrl = 'https://qm.qq.com/q/H3r6H1NRw6'
//快速注册
export const registerUrl = {
    text: '快速注册',
    url: '#'
}
//找回密码
export const retrievePasswordUrl = {
    text: '找回密码',
    url: '#'
}
export const datumUrl = {
    text: '资料变更',
    url: '#'
}
//封号查询
export const illegalityUrl = {
    text: '封号查询',
    url: '#'
}

// -------------------------底部区域------------------------------------
//游戏下载
export const gameDownUrl = 'https://qm.qq.com/q/H3r6H1NRw6'
//CDK兑换
export const cdkUrl = {
    img: require('../assets/gift.png'),
    text: 'CDK兑换',
    url: 'https://tieba.baidu.com/f?kw=%E9%9F%B3%E9%80%9F%E4%B9%90%E8%B6%A3&tp=0'
}
//密码找回
export const passwordRetrieveUrl = {
    img: require('../assets/suo.png'),
    text: '密码找回',
    url: 'https://tieba.baidu.com/f?kw=%E9%9F%B3%E9%80%9F%E4%B9%90%E8%B6%A3&tp=0'
}
//玩家论坛
export const discussUrl = {
    img: require('../assets/taolun.png'),
    text: '玩家论坛',
    url: 'https://tieba.baidu.com/f?kw=%E9%9F%B3%E9%80%9F%E4%B9%90%E8%B6%A3&tp=0'
}
//客服中心
export const serviceUrl = {
    img: require('../assets/kefu.png'),
    text: '客服中心',
    url: 'https://tieba.baidu.com/f?kw=%E9%9F%B3%E9%80%9F%E4%B9%90%E8%B6%A3&tp=0'
}

// -------------------------tab部分-------------------------------------
// 全部资讯
export const allMessage = [
    {
        text: '更新通知',
        time: '2023-10-07',
        url: 'https://tieba.baidu.com/f?kw=%E9%9F%B3%E9%80%9F%E4%B9%90%E8%B6%A3&tp=0',
    },
    {
        text: '新歌速递',
        time: '2023-10-07',
        url: 'https://tieba.baidu.com/p/8512080341',
    },
    {
        text: '紫钻上线啦！',
        time: '2023-10-07',
        url: 'https://tieba.baidu.com/f?kw=%E9%9F%B3%E9%80%9F%E4%B9%90%E8%B6%A3&tp=0',
    },
    {
        text: '金币的水贴！',
        time: '2023-10-07',
        url: 'https://tieba.baidu.com/p/8623292805?pid=148685134763&cid=0#148685134763',
    },
    {
        text: '金币的水贴2！',
        time: '2023-10-07',
        url: 'https://tieba.baidu.com/p/8623292805?pid=148685134763&cid=0#148685134763',
    },

]

// 新闻
export const journalism = [
    {
        text: '新闻新闻新闻新闻新闻新闻新闻新闻',
        time: '2023-10-07',
        url: 'https://tieba.baidu.com/f?kw=%E9%9F%B3%E9%80%9F%E4%B9%90%E8%B6%A3&tp=0',
    },
    {
        text: '新闻闻新闻新闻新闻新闻新闻',
        time: '2023-10-07',
        url: 'https://tieba.baidu.com/f?kw=%E9%9F%B3%E9%80%9F%E4%B9%90%E8%B6%A3&tp=0',
    },
    {
        text: '新闻闻新闻新闻新闻新闻新闻',
        time: '2023-10-07',
        url: 'https://tieba.baidu.com/f?kw=%E9%9F%B3%E9%80%9F%E4%B9%90%E8%B6%A3&tp=0',
    },
    {
        text: '新闻闻新闻新闻新闻新闻新闻',
        time: '2023-10-07',
        url: 'https://tieba.baidu.com/f?kw=%E9%9F%B3%E9%80%9F%E4%B9%90%E8%B6%A3&tp=0',
    },
    {
        text: '新闻闻新闻新闻新闻新闻新闻',
        time: '2023-10-07',
        url: 'https://tieba.baidu.com/f?kw=%E9%9F%B3%E9%80%9F%E4%B9%90%E8%B6%A3&tp=0',
    },
]
// 公告
export const notice = [
    {
        text: '公告公告公告公告公告公告公告',
        time: '2023-10-07',
        url: 'hhttps://tieba.baidu.com/f?kw=%E9%9F%B3%E9%80%9F%E4%B9%90%E8%B6%A3&tp=0',
    },
    {
        text: '公告公告公告公告公告公告公告',
        time: '2023-10-07',
        url: 'https://tieba.baidu.com/f?kw=%E9%9F%B3%E9%80%9F%E4%B9%90%E8%B6%A3&tp=0',
    },
    {
        text: '公告公告公告公告公告公告公告',
        time: '2023-10-07',
        url: 'https://tieba.baidu.com/f?kw=%E9%9F%B3%E9%80%9F%E4%B9%90%E8%B6%A3&tp=0',
    },
    {
        text: '公告公告公告公告公告公告公告',
        time: '2023-10-07',
        url: 'https://tieba.baidu.com/f?kw=%E9%9F%B3%E9%80%9F%E4%B9%90%E8%B6%A3&tp=0',
    },
    {
        text: '公告公告公告公告公告公告公告',
        time: '2023-10-07',
        url: 'https://tieba.baidu.com/f?kw=%E9%9F%B3%E9%80%9F%E4%B9%90%E8%B6%A3&tp=0',
    },
]
// 活动
export const activity = [
    {
        text: '活动活动活动活动活动',
        time: '2023-10-07',
        url: 'https://tieba.baidu.com/f?kw=%E9%9F%B3%E9%80%9F%E4%B9%90%E8%B6%A3&tp=0',
    },
    {
        text: '活动活动活动活动活动',
        time: '2023-10-07',
        url: 'https://tieba.baidu.com/f?kw=%E9%9F%B3%E9%80%9F%E4%B9%90%E8%B6%A3&tp=0',
    },
    {
        text: '活动活动活动活动活动',
        time: '2023-10-07',
        url: 'https://tieba.baidu.com/f?kw=%E9%9F%B3%E9%80%9F%E4%B9%90%E8%B6%A3&tp=0',
    },
    {
        text: '活动活动活动活动活动',
        time: '2023-10-07',
        url: 'https://tieba.baidu.com/f?kw=%E9%9F%B3%E9%80%9F%E4%B9%90%E8%B6%A3&tp=0',
    },
    {
        text: '活动活动活动活动活动',
        time: '2023-10-07',
        url: 'https://tieba.baidu.com/f?kw=%E9%9F%B3%E9%80%9F%E4%B9%90%E8%B6%A3&tp=0',
    },
]



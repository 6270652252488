<!-- 首页 -->
<template >
  <div style="position:relative;">
    <div class="box">
      <div class="nav">
        <div class="navLeft">
          <a :href="homeUrl" target="_blank" class="navItem">官网首页</a>
          <a :href="downUrl" target="_blank" class="navItem">下载专区</a>
        </div>
        <div class="navRight">
          <a :href="introductionUrl" target="_blank" class="navItem">游戏介绍</a>
          <a :href="feedbackUrl" target="_blank" class="navItem">问题反馈</a>
        </div>
        <img src="./assets/顶部logo.png" alt="" class="logoImg">
      </div>
      <div class="header">

      </div>
      <!-- 下载中心 -->
      <div class="downloadCenter">
        <a :href="downCenterUrl" target="_blank" style="width:200px;height:180px;position: absolute;top: 0px;"></a>
        <div class="content">
          <div class="downloadCenterbox">
            <a :href="registerUrl.url" target="_blank" class="downloadCenterItem">{{registerUrl.text}}</a>
          </div>
          <div class="downloadCenterbox">
            <a :href="retrievePasswordUrl.url" target="_blank" class="downloadCenterItem purple">{{retrievePasswordUrl.text}}</a>
          </div>
          <div class="downloadCenterbox">
            <a :href="datumUrl.url" target="_blank" class="downloadCenterItem purple">{{datumUrl.text}}</a>
          </div>
          <div class="downloadCenterbox">
            <a :href="illegalityUrl.url" target="_blank" class="downloadCenterItem">{{illegalityUrl.text}}</a>
          </div>
        </div>

      </div>
      <div class="main">
        <div class="mainTop">
          <div class="one">
            <el-carousel trigger="click" height="160px">
              <el-carousel-item v-for="(item,index) in bannerList" :key="index">
                <a :href="item.url" target="_blank"> <el-image style="width: 100%; height: 100%" fit="cover" :src="item.img"></el-image></a>
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="two">
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane label="全部" name="first">
                <div class="twoItem" v-for="(item,index) in allMessage " :key="index">
                  <template v-if="index < 5">
                    <a :href="item.url" target="_blank"> {{item.text}}</a><i>{{item.time}}</i>
                  </template>
                </div>
              </el-tab-pane>
              <el-tab-pane label="新闻" name="second">
                <div class="twoItem" v-for="(item,index) in journalism " :key="index">
                  <template v-if="index < 5">
                    <a :href="item.url" target="_blank"> {{item.text}}</a><i>{{item.time}}</i>
                  </template>
                </div>
              </el-tab-pane>
              <el-tab-pane label="公告" name="third">
                <div class="twoItem" v-for="(item,index) in notice " :key="index"> <template v-if="index < 5">
                    <a :href="item.url" target="_blank"> {{item.text}}</a><i>{{item.time}}</i>
                  </template></div>
              </el-tab-pane>
              <el-tab-pane label="活动" name="fourth">
                <div class="twoItem" v-for="(item,index) in activity " :key="index"> <template v-if="index < 5">
                    <a :href="item.url" target="_blank"> {{item.text}}</a><i>{{item.time}}</i>
                  </template></div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
        <div class="mainBottom">
          <div class="three">
            <a :href="popMusic.url" target="_blank"> <el-image style="width: 100%; height: 100%" fit="cover" :src="popMusic.img"></el-image>
              <div class="mainBottomTitle">
                {{popMusic.text}}
              </div>
            </a>
          </div>
          <div class="four">
            <a :href="popMusic.url" target="_blank"> <el-image style="width: 100%; height: 100%" fit="cover" :src="tide.img"></el-image>
              <div class="mainBottomTitle">
                {{tide.text}}
              </div>
            </a>
          </div>
          <div class="five">
            <div class="fiveTop">
              <img src="./assets/xiazai.png" alt="" style="width:35px;height:35px;  position: absolute;top: 20px;left: 50px;">

              <a :href="gameDownUrl" target="_blank" style="color:#fff">游戏下载</a>
            </div>
            <div class="fiveBottom">
              <div class="fiveBottomItem">
                <img :src="cdkUrl.img" alt="" style="width:15px;height:15px; ">
                <a :href="cdkUrl.url" target="_blank">{{cdkUrl.text}}</a>
              </div>

              <div class="fiveBottomItem">
                <img :src="passwordRetrieveUrl.img" alt="" style="width:15px;height:15px; ">
                <a :href="passwordRetrieveUrl.url" target="_blank">{{passwordRetrieveUrl.text}}</a>
              </div>
              <div class="fiveBottomItem">
                <img :src="discussUrl.img" alt="" style="width:15px;height:15px; ">
                <a :href="discussUrl.url" target="_blank">{{discussUrl.text}}</a>
              </div>
              <div class="fiveBottomItem">
                <img :src="serviceUrl.img" alt="" style="width:15px;height:15px; ">
                <a :href="serviceUrl.url">{{serviceUrl.text}}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 图片列表 -->
      <!-- <div class="imgList">
        <div class="imgListItem" v-for="item in imgList" :key="item.img">
          <img :src="item.img" alt="" class="img">
          <span class="describe">{{item.text}}</span>
        </div>
      </div> -->
      <!-- 底部轮播图 -->
      <!-- <div style="display: flex; justify-content: center;align-items: center;"> -->
        <!-- <div style="box-sizing:border-box;padding-top:100px;width:70%"> -->
          <!-- <video src="./assets/video.mp4" style="margin 0 auto;" autoplay></video> -->
          <!-- <el-carousel trigger="click" height="600px">
            <el-carousel-item v-for="(item,index) in bannerBottomList" :key="index">
              <a :href="item.url" target="_blank"> <el-image style="width: 100%; height: 100%" fit="cover" :src="item.img"></el-image></a>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div> -->
      <!-- 底部信息 -->
      <div class="bottomExplain">
        <div style="color:#fff;font-size: 12px;text-align: center; width:400px;height:30px">
          <div style="float:left;padding:5px">
            <img src="./assets/底部logo.png" alt="" style="text-align: center;">
          </div>
          <div style="float:left">
            {{explain}} <br>
            {{explains}}
          </div>
        </div>
        <div style="text-align: center;padding:10px 0;position:"><img src="./assets/底部合作.png" alt=""></div>
        <div style="color:#fff;font-size: 16px;text-align: center;color:#fff"><a :href="remarkUrl" target="_blank"></a>{{remark}}</div>
      </div>
      <!-- 播放音乐的按钮 -->
      <div class="playBtn">
        <div @click="musicPlay" v-if="isPlayMus">
          <img src="./assets/音乐按钮.png" alt="" style="">
        </div>
        <div @click="musicPause" v-else> <img src="./assets/关闭音乐.png" alt="" style=""></div>
      </div>
      <audio :src="audioUrl" class="media-audio" loop ref="MusicPlay"></audio>
      <img v-if="showinform" src="./assets/免费弹窗.png" alt="" style="position:absolute;top: 19%;z-index: 999; left: 36%;">
      <div @click="showinform=!showinform" class="hidePop"></div>
    </div>

  </div>
</template>

<script>
// 视频播放页面
// import videojs from 'video.js';
import { remarkUrl, imgList, bannerBottomList, videoSrc, explains, audioUrl, notice, explain, activity, bannerList, homeUrl, downUrl, introductionUrl, feedbackUrl, registerUrl, retrievePasswordUrl, datumUrl, illegalityUrl, gameDownUrl, cdkUrl, serviceUrl, passwordRetrieveUrl, discussUrl, popMusic, tide, allMessage, journalism, remark, downCenterUrl } from './utils/index'
export default {
  components: {},
  data() {

    return {
      zoom: {
        width: 1,
        height: 1
      },
      imgList,
      remarkUrl,
      bannerBottomList,
      videoSrc,
      explains,
      showinform: true,
      isPlayMus: true,
      activeName: 'first',
      explain,
      bannerList,
      popMusic,
      tide,
      allMessage,
      journalism,
      audioUrl,
      remark,
      downCenterUrl,
      notice, activity,
      homeUrl, downUrl, introductionUrl, feedbackUrl, registerUrl, retrievePasswordUrl, datumUrl, illegalityUrl, gameDownUrl, cdkUrl, serviceUrl, passwordRetrieveUrl, discussUrl
    }
  },
  computed: {},
  watch: {},
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    musicPause() {
      this.$refs.MusicPlay.pause()
      this.isPlayMus = true
    },
    musicPlay() {
      this.$refs.MusicPlay.play()
      this.isPlayMus = false
    },
  },
  created() {

  },
  mounted() {
  },
}
</script>
<style lang='scss' scoped>
.box {
  position: relative;
  width: 1920px;
  height: auto;
  // height: 100vh;
  margin: 0 auto;
  background: url("./assets/底色.png") no-repeat;
}
/* 头部导购 */
.nav {
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  .navLeft {
    width: 260px;
    display: flex;
    justify-content: space-between;
    margin-right: 283px;
  }
  .navLeft a:first-child {
    padding-right: 20px;

    border-right: 1px solid #fff;
  }
  .navRight {
    width: 260px;
    display: flex;
    justify-content: space-between;
    /* margin-right: 50px; */
  }
  .navRight a:first-child {
    padding-right: 20px;
    border-right: 1px solid #fff;
  }
  .navItem {
    color: #fff;
    font-size: 24px;
    z-index: 999;
    cursor: pointer;
    opacity: 0;
    // &:hover {
    //   color: #a50080;
    // }
  }
}

.logoImg {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99;
}
/* 中间图片 */
.header {
  width: 100%;
  height: 480px;
  .headerImg {
    position: absolute;
    top: 35px;
    left: 50%;
    transform: translateX(-50%);
  }
  .headerImgLeft {
    position: absolute;
    top: 170px;
    left: 500px;
  }
  .headerImgRight {
    position: absolute;
    top: 170px;
    right: 500px;
  }
  .headerImgbottom {
    position: absolute;
    top: 568px;
  }
}

/* 下面内容 */
.main {
  margin: 0 auto;
  width: 800px;
  height: 300px;
  // margin-bottom: 280px;
  .mainTop {
    width: 100%;
    height: 160px;
    display: flex;
    justify-content: space-between;
    margin: 15px 0px;
  }
  .one {
    width: 435px;
    height: 100%;
    background-color: red;
  }
  .two {
    width: 340px;
    height: 100%;
    padding: 5px 10px;
    background-color: #fff;
    .twoItem {
      display: flex;
      justify-content: space-between;
      padding: 0px 10px;
      margin-bottom: 5px;
      & > a {
        width: 200px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        color: #000;
        font-size: 12px;
        &:hover {
          color: #a50080;
        }
      }
      & > i {
        color: #666;
        font-size: 12px;
      }
    }
  }
  .mainBottom {
    width: 100%;
    height: 120px;
    display: flex;
    justify-content: space-between;
  }
  .three {
    position: relative;
    flex: 1;
    height: 100%;
    background-color: red;
    margin-right: 10px;
  }
  .four {
    position: relative;
    flex: 1;
    height: 100%;
    background-color: yellow;
    margin-right: 10px;
  }
  .five {
    position: relative;
    flex: 1;
    height: 100%;
    margin-right: 0px;
  }
  .fiveTop {
    width: 100%;
    height: 75px;
    background: #dc1d4a;
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    padding: 20px 0px 0px 100px;
    cursor: pointer;
  }
  .fiveBottom {
    height: 40px;
    display: flex;
    align-items: center;
  }
  .fiveBottomItem {
    margin-top: 10px;
    border-radius: 5px;
    padding: 3px 0px;
    background-color: #f4eed1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex: 1;
    color: #898989;
    font-size: 12px;
  }
  .mainBottomTitle {
    padding: 5px 15px;
    /* width: 150px;
  height: 50px; */
    right: 0px;
    position: absolute;
    top: 35px;
    background-color: rgba(83, 77, 101, 0.5);
    color: #fff;
    font-size: 28px;
    text-align: center;
    /* line-height: 50px; */
  }
}
.imgList {
  margin-top: 25px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 559px;
  height: 235px;
  // background-color: #000;
  .imgListItem {
    position: relative;
    width: 195px;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .img {
      width: 100%;
      height: 100%;
      transition: all 0.5s;
    }
    .describe {
      position: absolute;
      bottom: 0px;
      display: inline-block;
      transform: translateY(30px);
      width: 100%;
      height: 30px;
      font-size: 24px;
      color: #fff;
      background-color: #31359c;
      transition: all 0.5s;
      text-align: center;
      line-height: 30px;
    }
    &:hover .img {
      transform: scale(1.1);
    }
    &:hover .describe {
      transform: translateY(0px);
    }
  }
}
/* 下载中心 */
.downloadCenter {
  width: 183px;
  height: 410px;
  position: absolute;
  top: 485px;
  left: 380px;
  background: url("./assets/导航栏.png") no-repeat;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  padding: 180px 0px 50px;
}
.content {
  height: 180px;
  width: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  font-weight: 700;
}
.content .downloadCenterbox:last-child {
  border-bottom: none;
}
.downloadCenterbox {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-bottom: 1px dotted #fff;
  color: #a50080;
  cursor: pointer;
  & > a {
    color: #fff;
  }
}
.purple {
  width: 100px;
  border-radius: 10px;
  background-color: #a50080;
  font-size: 16px;
  color: #fff;
  font-weight: 700;
  text-align: center;
  padding: 2px;
}
.bottomExplain {
  margin-top: 50px;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}
.playBtn {
  position: absolute;
  top: 154px;
  right: 360px;
}
// 隐藏弹窗按钮
.hidePop {
  width: 150px;
  height: 45px;
  position: absolute;
  // top: 759px; 带轮播图
  top: 571px; 
  z-index: 999;
  left: 46%;
  cursor: pointer;
}
/deep/.el-tabs__header {
  margin: 0 0 10px;
}
/deep/.el-tabs__nav-wrap {
  padding-left: 20px;
}
/deep/ .el-tabs__item {
  color: #409eff;
  font-weight: 700;
}
/deep/ .el-tabs__item.is-active {
  color: #a50080;
  font-weight: 700;
}
/deep/ .el-tabs__active-bar {
  // transform: translateY(-5px) !important;
  height: 3px;
  bottom: 5px;
  background-color: #a50080;
}
/deep/ .el-tabs__nav-wrap::after {
  // bottom: -3px;
  height: 3px;
  background-color: #918f8d;
}
</style>